import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';
import { setSeasonGames, setStateNrOfGames, setStateOpenSetupGames } from './seasonSlice';
import { setStateSeasonStarted } from './seasonSlice';
// import { setTotalGames } from './seasonSlice';
import { seasonRequests } from '../../services/api-requests';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TeamSeasonModal from './teamSeasonModal';
import { schoolNames } from '../../services/schoolNames';


export function SetupGames(props) {
    const navigate = useNavigate()
    console.log('props: ', props)
    const dispatch = useDispatch();
    const [gameFields, setGameFields] = useState([]);
    const [typeOfGame, setTypeOfGame] = useState('');
    const [seasonStarted, setSeasonStarted] = useState(false);
    const [openSetupGames, setOpenSetupGames] = useState(false);
    const [gameIdCounter, setGameIdCounter] = useState(1); // Initialize the game ID counter
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [seasonDuplicatedFrom, setSeasonDuplicatedFrom] = useState(0);
    const [teamId, setTeamId] = useState('');
    const stateSeasonStarted = useSelector((state) => state.season.seasonStarted);
    const nrOfGames = useSelector((state) => state.season.season[0].totalGames);
    // console.log('New Nr Of games from slice: ', nrOfGames)
    const oldNrOfGames = useSelector((state) => state.season.totalGames);
    // console.log('Old Nr Of games from slice: ', oldNrOfGames)
    const oldSeasonGames = useSelector((state) => state.season.games)
    const seasonGames = useSelector((state) => state.season.season[0].games)
    const stateSeasonObj = useSelector((state) => state.season.season[0])
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const activeSeason = useSelector((state) => state.season.activeSeason);
    const [seasonForTeam, setSeasonForTeam] = useState([]);
    const [duplicateObject, setDuplicateObject] = React.useState({});
    const [isTeamSeasonModal, setIsTeamSeasonModal] = useState(false);
    const stateTeams = useSelector((state) => state.team.availableTeams)
    const activeTeam = useSelector((state) => state.season.activeSeason);
    const correctNrOfGames = parseInt(useSelector((state) => state.season.nrOfGames))


    // const [schoolNames, setSchoolNames] = useState([
    //     "Afrikaanse Hoër Seunskool",
    //     "Hoërskool Ben Vorster",
    //     "Benoni High",
    //     "CBC Boksburg",
    //     "Boland Landbou",
    //     "Hoërskool Brandwag",
    //     "Hoërskool Centurion",
    //     "Hoërskool Dr. EG Jansen",
    //     "Hoërskool Eldoraigne",
    //     "Hoërskool Ermelo",
    //     "Hoërskool Frikkie Meyer",
    //     "Grey College",
    //     "Hoërskool Helpmekaar",
    //     "Hts John Vorster",
    //     "Hts Middelburg",
    //     "Hoërskool Kempton Park",
    //     "Hoërskool Marais Viljoen",
    //     "Hoërskool Marlow",
    //     "Hoërskool Menlo Park",
    //     "Hoërskool Garsfontein",
    //     "Hoërskool Merensky",
    //     "Hoërskool Monument",
    //     "Hoërskool Nelspruit",
    //     "Hoërskool Noordheuwel",
    //     "Hoërskool Rustenburg",
    //     "Hoërskool Oos Moot",
    //     "Hugenote",
    //     "Hoërskool Outeniqua",
    //     "Paarl Boys High",
    //     "Paarl Gimnasium",
    //     "Pietersburg Hoerskool",
    //     "Hoërskool Piet Retief",
    //     "Hoërskool Transvalia",
    //     "Hoërskool Waterkloof",
    //     "Hoërskool Wonderboom",
    //     "Hoërskool Zwartkop"
    // ]);

    const schools = [
        { name: "Affies", category: "Gauteng" },
        { name: "Centurion", category: "Gauteng" },
        { name: "Eldoraigne", category: "Gauteng" },
        { name: "Garsfontein", category: "Gauteng" },
        { name: "Helpmekaar", category: "Gauteng" },
        { name: "Jeppe", category: "Gauteng" },
        { name: "Kempton Park", category: "Gauteng" },
        { name: "King David", category: "Gauteng" },
        { name: "KES", category: "Gauteng" },
        { name: "Marais Viljoen", category: "Gauteng" },
        { name: "Menlo Park", category: "Gauteng" },
        { name: "Monument", category: "Gauteng" },
        { name: "Noordheuwel", category: "Gauteng" },
        { name: "Oos Moot", category: "Gauteng" },
        { name: "Pretoria Boys High", category: "Gauteng" },
        { name: "St Albans College", category: "Gauteng" },
        { name: "St John's College", category: "Gauteng" },
        { name: "St Stithians", category: "Gauteng" },
        { name: "Transvalia", category: "Gauteng" },
        { name: "Waterkloof", category: "Gauteng" },
        { name: "Wonderboom", category: "Gauteng" },
        { name: "Zwartkop", category: "Gauteng" },
        { name: "Alberton", category: "Gauteng" },
        { name: "Fourways", category: "Gauteng" },
        { name: "Springs Boys", category: "Gauteng" },
        { name: "St Dunstan's", category: "Gauteng" },
        { name: "Cornwall Hill", category: "Gauteng" },
        { name: "St David's", category: "Gauteng" },
        { name: "St Peter's", category: "Gauteng" },
        { name: "Sutherland", category: "Gauteng" },
        { name: "Northcliff", category: "Gauteng" },
        { name: "Ben Vorster", category: "Limpopo" },
        { name: "Frikkie Meyer", category: "Limpopo" },
        { name: "Merensky", category: "Limpopo" },
        { name: "Pietersburg", category: "Limpopo" },
        { name: "Ermelo", category: "Mpumalanga" },
        { name: "Middelburg HS", category: "Mpumalanga" },
        { name: "Nelspruit", category: "Mpumalanga" },
        { name: "Hts Middelburg", category: "Mpumalanga" },
        { name: "DHS", category: "KwaZulu-Natal" },
        { name: "Glenwood", category: "KwaZulu-Natal" },
        { name: "Hilton College", category: "KwaZulu-Natal" },
        { name: "Kearsney College", category: "KwaZulu-Natal" },
        { name: "Maritzburg College", category: "KwaZulu-Natal" },
        { name: "Michaelhouse", category: "KwaZulu-Natal" },
        { name: "Northwood", category: "KwaZulu-Natal" },
        { name: "Westville", category: "KwaZulu-Natal" },
        { name: "St Charles College", category: "KwaZulu-Natal" },
        { name: "Bishops", category: "Western Cape" },
        { name: "Boland Landbou", category: "Western Cape" },
        { name: "Durbanville", category: "Western Cape" },
        { name: "Milnerton", category: "Western Cape" },
        { name: "Oakdale", category: "Western Cape" },
        { name: "Outeniqua", category: "Western Cape" },
        { name: "Paarl Boys High", category: "Western Cape" },
        { name: "Paarl Gimnasium", category: "Western Cape" },
        { name: "Paul Roos Gym", category: "Western Cape" },
        { name: "Rondebosch", category: "Western Cape" },
        { name: "SACS", category: "Western Cape" },
        { name: "Stellenberg", category: "Western Cape" },
        { name: "Wynberg BH", category: "Western Cape" },
        { name: "Worcester Gym", category: "Western Cape" },
        { name: "Brandwag", category: "Eastern Cape" },
        { name: "Framesby", category: "Eastern Cape" },
        { name: "Graeme College", category: "Eastern Cape" },
        { name: "Grey High School", category: "Eastern Cape" },
        { name: "Hudson Park", category: "Eastern Cape" },
        { name: "Kingswood College", category: "Eastern Cape" },
        { name: "Marlow", category: "Eastern Cape" },
        { name: "Nico Malan", category: "Eastern Cape" },
        { name: "Pearson", category: "Eastern Cape" },
        { name: "Queens College", category: "Eastern Cape" },
        { name: "Selborne College", category: "Eastern Cape" },
        { name: "St Andrews College", category: "Eastern Cape" },
        { name: "Grey College", category: "Free State" },
        { name: "Voortrekker Beth", category: "Free State" },
        { name: "Welkom Gim", category: "Free State" },
        { name: "Diamantveld", category: "Northern Cape" },
        { name: "Potch Boys", category: "North West" }
    ];


    // const [schoolNames, setSchoolNames] = useState([
    //     "Affies",
    //     "Alberton",
    //     "Ben Vorster",
    //     "Bishops",
    //     "Boland Landbou",
    //     "Benoni High",
    //     "Brandwag",
    //     "Centurion",
    //     "CBC Boksburg",
    //     "Cornwall Hill",
    //     "Charlie Hofmeyr",
    //     "Diamantveld",
    //     "DHS",
    //     "Dr. EG Jansen",
    //     "Durban High School",
    //     "Durbanville",
    //     "Eldoraigne",
    //     "Ermelo",
    //     "Fourways",
    //     "Framesby",
    //     "Frikkie Meyer",
    //     "Garsfontein",
    //     "Glenwood",
    //     "Graeme College",
    //     "Grey College",
    //     "Grey High School",
    //     "Helpmekaar",
    //     "Hilton College",
    //     "HTS Drostdy",
    //     "Hts John Vorster",
    //     "Hts Middelburg",
    //     "Jeppe",
    //     "Kearsney College",
    //     "Kempton Park",
    //     "KES",
    //     "Kingswood College",
    //     "King David",
    //     "Marais Viljoen",
    //     "Marlow",
    //     "Maritzburg College",
    //     "Menlo Park",
    //     "Merensky",
    //     "Michaelhouse",
    //     "Middelburg HS",
    //     "Milnerton",
    //     "Monument",
    //     "Nelspruit",
    //     "Nico Malan",
    //     "Noordheuwel",
    //     "Northwood",
    //     "Northcliff",
    //     "Oakdale",
    //     "Oos Moot",
    //     "Outeniqua",
    //     "Paarl Boys High",
    //     "Paarl Gimnasium",
    //     "Paul Roos Gym",
    //     "Pearson",
    //     "Piet Retief",
    //     "Pietersburg",
    //     "Potch Boys",
    //     "Pretoria Boys High",
    //     "Queens College",
    //     "Rondebosch",
    //     "Rustenburg",
    //     "SACS",
    //     "Selborne College",
    //     "Springs Boys",
    //     "St Albans College",
    //     "St Andrews College",
    //     "St Benedict's",
    //     "St Charles College",
    //     "St David's",
    //     "St Dunstan's",
    //     "St Johns College",
    //     "St Peter's",
    //     "St Stithians",
    //     "Stellenberg",
    //     "Sutherland",
    //     "Transvalia",
    //     "Voortrekker Beth",
    //     "Waterkloof",
    //     "Welkom Gim",
    //     "Westville",
    //     "Wonderboom",
    //     "Worcester Gym",
    //     "Wynberg Boys' High",
    //     "Zwartkop",
    // ]);




    ///King David 
    ///Springs Boys 
    ///Alberton
    ///Fourways 
    ///St Dunstan's 
    ///Cornwall Hill  St David's St Peter's  Sutherland   Northcliff  Potch Boys   St Benedict's

    // console.log('Season object received: ', stateSeasonObj)

    // console.log('NrOfGames received: ', nrOfGames)

    useEffect(() => {
        // console.log('Season games: ', seasonGames)
    }, [seasonGames])


    useEffect(() => {
        setIsLoading(true)
        const teamIWant = availableTeams.find((team) => team.name === activeSeason)

        const localTeamId = teamIWant?.id
        // console.log('TeamID I want: ', localTeamId)
        setTeamId(localTeamId)

        setIsLoading(false)
    }, [stateSeasonStarted, setStateOpenSetupGames, activeSeason])


    //// Initialize gameFields based on nrOfGames
    useEffect(() => {
        const initialGameFields = Array.from({ length: correctNrOfGames }, (_, index) => ({
            //   typeId: gameIdCounter + index, // Assign a unique ID to each game
            typeId: 0,
            opponent: '',
            date: '',
            venue: '',

        }));
        setGameFields(initialGameFields);
        // console.log('Hit')
    }, [props]);

    async function startSeason() {
        // console.log('Start season has been started')

        const seasonObj = {
            schoolId: schoolId,
            teamId: teamId,
            typeId: stateSeasonObj.seasonType,
            startDate: stateSeasonObj.seasonStartDate,
            endDate: stateSeasonObj.seasonEndDate,
            games: gameFields,
        }


        //Identify when we need to duplicate, and when not to

        console.log('Going to Create Season: ', seasonObj)

        // console.log('Are we going to duplicate? ', seasonForTeam.length)
        // console.log('Going to Duplicate Season: ', duplicateObject)

        try {
            setIsLoading(true)
            const response = await seasonRequests.createNewSeason(seasonObj)

            if (response.status === 200) {
                console.log('Response from create Season: ', response.data)
                setError(false)

                setConfirmationMessage('Season has been Created')
                setOpenConfirmationSnackbar(true)

            }
            else {
                console.log('Could not create season: ', response)
                setError(true)
                setConfirmationMessage('Could not create season')
                setOpenConfirmationSnackbar(true)
            }
        } catch (error) {
            console.log('Could not create season: ', error)
        } finally {

            //Most probably going to duplicate here:
            if (seasonForTeam.length > 0) {
                try {
                    const response = await seasonRequests.DuplicateSeason(duplicateObject)
                    console.log('Response from duplicate: ', response)
                    if (response.status === 200) {
                        console.log('Response from Duplicate Season: ', response.data)
                        setError(false)
                        setConfirmationMessage('Season has been Duplicated successfully')
                        setOpenConfirmationSnackbar(true)
                    }
                    else {
                        setError(true)
                        setConfirmationMessage('Could not duplicate season')
                        setOpenConfirmationSnackbar(true)
                    }
                } catch (error) {
                    console.log('Error in duplicate: ', error)
                    setError(true)
                    setConfirmationMessage('Could not duplicate season')
                    setOpenConfirmationSnackbar(true)
                }
            }

            //reset nr of games:
            dispatch(setStateNrOfGames(0))

            //This is never used
            setIsLoading(false)
            //This is never used
            setSeasonStarted(true)
        }

    }

    function onTeamsSelected(teams) {
        // console.log('Teams received from modal: ', teams)
        const myTeam = stateTeams.find((team) => team.name === activeTeam).id
        // console.log('My Team in setup: ', myTeam)
        setDuplicateObject(
            {
                teamToDuplicateId: myTeam,
                teamToDuplicateToId: teams[0]
            })
        setSeasonForTeam(teams)
    }

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            dispatch(setStateSeasonStarted(true))
            dispatch(setStateOpenSetupGames(false))
            //trigger callback
            props.refreshSeason()
            return;
        }
        dispatch(setStateSeasonStarted(true))
        dispatch(setStateOpenSetupGames(false))
        //trigger callback
        props.refreshSeason()

        setOpenConfirmationSnackbar(false);
    };

    // Update a specific field's value in gameFields
    const handleFieldChange = (event, fieldIndex, fieldName) => {
        console.log('Value we are working with: ', event.target.value)
        console.log('fieldIndex: ', fieldIndex)
        console.log('fieldName: ', fieldName)
        console.log('Exisiting Game Fields: ', gameFields)
        const newValue = event.target.value;
        setGameFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[fieldIndex][fieldName] = newValue;
            return updatedFields;
        });
    };

    // Render the dynamic fields
    const renderGameFields = () => {
        return gameFields.map((game, index) => (
            <React.Fragment key={index}>
                <Divider style={{ margin: '0 auto', width: '80%', marginBottom: '10px' }} />
                <p>Game {index + 1}</p>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={3}>
                        {/* <TextField
                            label="Opponent"
                            type="text"
                            value={game.opponent}
                            onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        /> */}

                        {/* <FormControl fullWidth>
                            <InputLabel id={`opponent-label-${index}`}>Opponent</InputLabel>
                            <Select
                                labelId={`opponent-label-${index}`}
                                label="Opponent"
                                id={`opponent-dropdown-${index}`}
                                value={game.opponent}
                                sx={{ width: '100%', marginBottom: '15px' }}
                                onChange={(event) => { handleFieldChange(event, index, 'opponent') }}
                            >
                                {
                                    schoolNames.map((school) => {
                                        return (
                                            <MenuItem key={school} value={school}>{school}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl> */}

                        <FormControl fullWidth sx={{ marginBottom: "15px" }}>

                            <Autocomplete
                                options={schoolNames}
                                value={game.opponent || ""}
                                onChange={(event, newValue) => handleFieldChange({ target: { value: newValue } }, index, "opponent")}
                                renderInput={(params) => (
                                    <TextField {...params} label="Opponent" variant="outlined" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Date"
                            type="date"
                            value={game.date}
                            onChange={(event) => handleFieldChange(event, index, 'date')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TextField
                            label="Venue"
                            type="text"
                            value={game.venue}
                            onChange={(event) => handleFieldChange(event, index, 'venue')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%', marginBottom: '15px' }}
                        /> */}
                        <FormControl fullWidth>
                            <InputLabel >Venue</InputLabel>
                            <Select
                                label="Venue"
                                value={game.venue}

                                onChange={(event) => handleFieldChange(event, index, 'venue')}
                                sx={{ width: '100%' }}
                            >
                                <MenuItem key="Home" value="Home">Home </MenuItem>
                                <MenuItem key="Away" value="Away"> Away</MenuItem>
                                <MenuItem key="Tour" value="Tour"> Tour</MenuItem>
                                <MenuItem key="Other" value="Other"> Other  </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel >Type Of Game</InputLabel>
                            <Select
                                label="Type of Game"
                                value={game.typeOfGame}
                                placeholder="Select Type of Game"
                                onChange={(event) => {
                                    handleFieldChange(event, index, 'typeId');
                                    // handleFieldChange(event, index, 'typeOfGame')
                                }
                                }
                                sx={{ width: '100%' }}
                            >
                                <MenuItem key="Friendly" value={1}>
                                    Friendly
                                </MenuItem>
                                <MenuItem key="League" value={2}>
                                    League
                                </MenuItem>
                                <MenuItem key="Derby" value={3}>
                                    Derby
                                </MenuItem>
                                <MenuItem key="TrainingSession" value={4}>
                                    Training Session
                                </MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
            </React.Fragment >
        ));
    };

    return (
        <div style={{ maxWidth: '100%', margin: '0 auto' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography variant='h5'>Set up games</Typography>
                <br />
            </div>

            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                    {/* <p>Duplicate this season</p> */}
                    <Button variant='contained' onClick={() => { setIsTeamSeasonModal(true) }}>
                        Duplicate this season for another team?
                    </Button>
                </div>
            </div>

            {/* Render the dynamic game fields */}
            {renderGameFields()}

            <br />
            <Button variant="contained" onClick={startSeason} style={{ marginBottom: '10px' }}>
                Start Season!
            </Button>
            <br />
            <Button variant="outlined" onClick={() => {
                dispatch(setStateOpenSetupGames(false));
                dispatch(setStateNrOfGames(0));
                navigate('/Season')
            }}>
                Back
            </Button>

            <TeamSeasonModal
                teams={stateTeams}
                isOpen={isTeamSeasonModal}
                setTeamsSessionModalOpen={setIsTeamSeasonModal}
                onTeamsSelected={onTeamsSelected}
            >
            </TeamSeasonModal>

            <Snackbar
                open={openConfirmationSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? "error" : "success"}
                >
                    {confirmationMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}