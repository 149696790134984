export const schoolNames = [
    "Affies",
    "Alberton",
    "Ben Vorster",
    "Bishops",
    "Boland Landbou",
    "Benoni High",
    "Bryanston",
    "Brandwag",
    "Centurion",
    "CBC Boksburg",
    "Cornwall Hill",
    "Charlie Hofmeyr",
    "Diamantveld",
    "DHS",
    "Dr. EG Jansen",
    "Durban High School",
    "Durbanville",
    "Eldoraigne",
    "Ermelo",
    "Fourways",
    "Framesby",
    "Frikkie Meyer",
    "Garsfontein",
    "Glenwood",
    "Graeme College",
    "Grey College",
    "Grey High School",
    "Helpmekaar",
    "Hilton College",
    "HTS Drostdy",
    "Hts John Vorster",
    "Hts Middelburg",
    "Jeppe",
    "Kearsney College",
    "Kempton Park",
    "KES",
    "Kingswood College",
    "King David",
    "Marais Viljoen",
    "Marlow",
    "Maritzburg College",
    "Menlo Park",
    "Merensky",
    "Michaelhouse",
    "Middelburg HS",
    "Milnerton",
    "Monument",
    "Nelspruit",
    "Nico Malan",
    "Noordheuwel",
    "Northwood",
    "Northcliff",
    "Oakdale",
    "Oos Moot",
    "Outeniqua",
    "Paarl Boys High",
    "Paarl Gimnasium",
    "Paul Roos Gym",
    "Pearson",
    "Piet Retief",
    "Pietersburg",
    "Potch Boys",
    "Pretoria Boys High",
    "Queens College",
    "Rondebosch",
    "Rustenburg",
    "SACS",
    "Selborne College",
    "Springs Boys",
    "St Albans College",
    "St Andrews College",
    "St Benedict's",
    "St Charles College",
    "St David's",
    "St Dunstan's",
    "St Johns College",
    "St Peter's",
    "St Stithians",
    "Stellenberg",
    "Sutherland",
    "Transvalia",
    "Voortrekker Beth",
    "Waterkloof",
    "Welkom Gim",
    "Westville",
    "Wonderboom",
    "Worcester Gym",
    "Wynberg Boys' High",
    "Zwartkop",
]